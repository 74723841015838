import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoSaveReportsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl + "/reports/",
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    saveDailyIncomes(shopId, date) {
        return this.Api.get('/dailyIncomesPdf/' + shopId + '/' + date, {responseType: 'blob'});
    }
    saveDailyIncomesXls(shopId, date) {
        return this.Api.get('/dailyIncomesXls/' + shopId + '/' + date, {responseType: 'blob'});
    }
    saveMonthlyIncomes(shopId, month, year) {
        return this.Api.get('/monthlyIncomesPdf/' + shopId + '/' + year + '/' + month, {responseType: 'blob'});
    }
    saveMonthlyIncomesXls(shopId, month, year) {
        return this.Api.get('/monthlyIncomesXls/' + shopId + '/' + year + '/' + month, {responseType: 'blob'});
    }
    saveCashRegister(shopId, date) {
        return this.Api.get('/cashRegisterPdf/' + shopId + '/' + date, {responseType: 'blob'});
    }
    saveCashRequirement(startDate) {
        return this.Api.get('/cashRequirementXls/all/' + startDate, {responseType: 'blob'});
    }
    saveFinancialReport(startDate, endDate, product) {
        return this.Api.get('/financialDataXls/' + startDate + '/' + endDate + '/' + product, {responseType: 'blob'});
    }
    saveTerminalsData(startDate, endDate) {
        return this.Api.get('/terminalShopsDataXls/' + startDate + '/' + endDate, {responseType: 'blob'});
    }
    saveManagementReport(startDate, endDate, product) {
        return this.Api.get('/managementDataXls/' + startDate + '/' + endDate + '/' + product, {responseType: 'blob'});
    }
    saveLiveManagementReport(startDate, endDate, installationType) {
        return this.Api.get('/managementDataLiveXls/' + startDate + '/' + endDate + '/' + installationType,{responseType: 'blob'});
    }
    saveProiectieIP(startDate, endDate, shopId, channel, products, franchiseid) {
        return this.Api.get('/incomesAndOutcomesNewXls/' + startDate + '/' + endDate + '/' + shopId + '/' + channel + '/' + products + '/' + franchiseid, {responseType: 'blob'});
    }
    saveFinancialOverview(startDate, endDate, shopId, products, franchiseid) {
        return this.Api.get('/financialOverviewXls/' + startDate + '/' + endDate + '/' + shopId + '/' + products + '/' + franchiseid, {responseType: 'blob'});
    }
    saveShopCD(shopId, startDate, endDate) {
        return this.Api.get('/getShopsCreditAndDebitXls/' + shopId + '/' + startDate + '/' + endDate, {responseType: 'blob'});
    }
    savePaidTickets (startDate, endDate, product, daysNo) {
        return this.Api.get('/paidTicketsXls/' + product + '/' + startDate + '/' + endDate + '/' + daysNo, { responseType: 'blob' })
    }
    saveFranchiseDecont (franchise, year, month) {
        return this.Api.get('/franchiseDecontPdf/' + franchise + '/' + year + '/' + month, { responseType: 'blob' })
    }
    saveDecontAnnual (shopId, year) {
        return this.Api.get('/annualDecontPdf/' + shopId + '/' + year, { responseType: 'blob' })
    }
    saveDecont (shopId, month, year) {
        return this.Api.get('/decontPdf/' + shopId + '/' + month + '/' + year, { responseType: 'blob' })
    }
    saveDecontXls (shopId, month, year) {
    return this.Api.get('/decontXls/' + shopId + '/' + month + '/' + year, { responseType: 'blob' })
    }
    saveWinsById (cardId) {
        return this.Api.get('/getWinsByIDXls/' + cardId, { responseType: 'blob' })
      }
    getStornoStats (startDate, endDate) {
        return this.Api.get('/getStornoTicketsStatsXls/' + startDate + '/' + endDate, { responseType: 'blob' })
      }
    saveAmlReport (startDate, endDate, winAmount) {
        return this.Api.get('/AMLDataXls/' + startDate + '/' + endDate + '/' + winAmount, { responseType: 'blob' })
      }
    saveEndOfDaySolds (date, reportType) {
        return this.Api.get('/saveEndOfDaySolds/' + date + '/' + reportType, { responseType: 'blob' })
      }
}

export {
    BoSaveReportsApi
}
