import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoReportsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl + "/reports/",
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    getDashboardData() {
        return this.Api.get('dashboardData/1')
    }
    getCashRegister(shopId, date) {
        return this.Api.get('cashRegister/' + shopId + '/' + date);
    }
    getDailyIncomes(shopId, date) {
    return this.Api.get('dailyIncomes/' + shopId + '/' + date);
    }
    getMonthlyIncomes(shopId, month, year) {
    return this.Api.get('monthlyIncomes/' + shopId + '/' + year + '/' + month);
    }
    getFisaCont(shopId, startDate, endDate) {
    return this.Api.get('fisaContCasa/' + shopId + '/' + startDate + '/' + endDate);
    }
    getCashRequirement(date) {
        return this.Api.get('cashRequirement/all/' + date);
    }
    getFinancialReport(startDate, endDate, product) {
        return this.Api.get('financialData/' + startDate + '/' + endDate + '/' + product);
    }
    getManagementReport(startDate, endDate, product) {
        return this.Api.get('managementData/' + startDate + '/' + endDate + '/' + product);
    }
    getLiveManagementReport(startDate, endDate, installationType) {
        return this.Api.get('managementDataLive/' + startDate + '/' + endDate + '/' + installationType);
    }
    getTerminalsData(startDate, endDate) {
        return this.Api.get('terminalShopsData/' + startDate + '/' + endDate);
    }
    getProiectieIP(startDate, endDate, shopId, channel, products, franchiseid) {
        return this.Api.get('incomesAndOutcomesNew/' + startDate + '/' + endDate + '/' + shopId + '/' + channel + '/' + products + '/' + franchiseid);
    }
    getFinancialOverview(startDate, endDate, shopId, products, franchiseid) {
        return this.Api.get('financialOverview/' + startDate + '/' + endDate + '/' + shopId + '/' + products + '/' + franchiseid);
    }
    getShopCD(shopId, startDate, endDate) {
        return this.Api.get('getShopsCreditAndDebit/' + shopId + '/' + startDate + '/' + endDate);
    }
    getPaidTickets (startDate, endDate, product, daysNo) {
        return this.Api.get('paidTickets/' + product + '/' + startDate + '/' + endDate + '/' + daysNo)
    }
    getAnnualDecont (shopId, year) {
        return this.Api.get('/annualDecont/' + shopId + '/' + year)
    }
    getFranchiseDecont (shopId, year, month) {
        return this.Api.get('/franchiseDecont/' + shopId + '/' + year + '/' + month)
    }
    getDecont (shopId, month, year) {
        return this.Api.get('/decont/' + shopId + '/' + month + '/' + year)
    }
    soldsHistory (requestObj) {
        return this.Api.post('/soldsHistory', requestObj)
    }
    getStornoStats (startDate, endDate) {
        return this.Api.get('/getStornoTicketsStats/' + startDate + '/' + endDate)
    }
    getWinsByID (cardId) {
        return this.Api.get('/getWinsByID/' + cardId)
    }
    getAmlReport (startDate, endDate, winAmount) {
        return this.Api.get('/AMLData/' + startDate + '/' + endDate + '/' + winAmount)
    }
    getEndOfDaySolds (date) {
        return this.Api.get('/endOfDaySolds/' + date)
    }
}

export {
    BoReportsApi
}
